import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Card, Button } from 'antd';
import { Animated } from "react-animated-css";


import './Organization.css';

import {
	listOrganizationalUnitsForParent
} from '../../modules/byteplus';

class Organization extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading:true
		}
	}

	async componentDidMount() {
		const { userProfile } = this.props;
		this.setState({loading:true});
		await this.props.listOrganizationalUnitsForParent({"ParentId":userProfile.user.organization}, userProfile.user.accountVersion);
		this.setState({loading:false});
	}

	async componentDidUpdate(prevProps) {

	}



	onGoClient(e) {
		this.props.history.push(`client/${e}`);
	}

	render() {
		const { organizationalUnits, userProfile } = this.props;
		// const { loading } = this.state;
		const _ou = organizationalUnits.filter(o=>o.ParentID === userProfile.user.organization);


		return (
			<Animated animationIn="fadeIn">
				<div className="mainContentWrapper" style={{ paddingBottom: (this.props.play) ? '100px' : '20px' }}>
					<Row>
						<Col md={24} lg={24} xl={24} xxl={24}>
								<Row>
									 {_ou && _ou.map((ou,i)=>
										 <Col key={i} xs={24} md={12} lg={6} xl={6} xxl={6}>
										 <Card
								       title={ou.Name}
								       extra={<Button type="link" onClick={()=>{this.onGoClient(ou.ID)}}>View</Button>}
								     >
								       <p>{ou.Description}</p>
								     </Card>
										 </Col>
									 )
									 }

								</Row>
						</Col>
					</Row>
				</div>
			</Animated>
		);
	}


}

const mapStateToProps = state => ({
	router: state.router,
	userProfile: state.user.userProfile,
	organizationalUnits: state.byteplus.organizationalUnits

});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			listOrganizationalUnitsForParent
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
