import axios from 'axios';
import config from '../../config';

import tokenApi from './tokenApi';

const userInstance = axios.create({
	baseURL: config.api,
	headers: {
		'Content-Type': 'application/json'
	}
});

userInstance.interceptors.response.use(
	response => responseSuccessHandler(response),
	error => responseErrorHandler(error)
);

const responseSuccessHandler = response => {
	return response;
};

const responseErrorHandler = error => {
	if (!error.response || error.response.status === 401) {
		const event = document.createEvent("Event");
		event.initEvent("SessionExpired", true, true);
		document.dispatchEvent(event);
	}
	return Promise.reject(error);
}


class UserApi {
	static UserConfirmPassword(payload) {
		return userInstance.post('/auth/login', payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static UserLogin(payload) {
		return userInstance.post('/auth/login', payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static UserInsert(payload) {
		userInstance.defaults.headers.common['Authorization'] = tokenApi.GetToken();
		return userInstance.post(`/users`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static UserUpdate(payload, id) {
		userInstance.defaults.headers.common['Authorization'] = tokenApi.GetToken();

		return userInstance.patch(`/users/${id}`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static UserList() {
		userInstance.defaults.headers.common['Authorization'] = tokenApi.GetToken();
		return userInstance.get(`/users`)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

	static UserDelete(payload) {
		userInstance.defaults.headers.common['Authorization'] = tokenApi.GetToken();

		return userInstance.post(`/auth/delete`, payload)
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			throw(error);
		});
	}

}

export default UserApi;
