import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Drawer } from 'antd';

import UserProfile from './usermanagement/UserProfile';
import Login from './login/Login';
import LoginCard from './login/LoginCard';

import Organization from './organization/Organization';
import OrganizationNew from './organization/OrganizationNew';
import OrganizationDetail from './organization/OrganizationDetail';

import Client from './client/Client';
import ClientNew from './client/ClientNew';

/* Components */
import SideNav from './sidenav/SideNav';
import TopNav from './topnav/TopNav';

/* Other pages */
import NotFound from './otherpages/NotFound';


import './app.css';

import {
	listOrganizationalUnitsForParent
} from '../modules/byteplus';

import {
	userTimeout
} from '../modules/user';

const ScrollToTopPage = () => {
	window.scrollTo(0, 0);
	return null;
};

class App extends Component {
	componentDidMount() {
		const { userTheme, userNav, userProfile } = this.props;
		if(this.props.router.location.pathname === '/login'){
			document.body.setAttribute("data-theme",'light');
		}else{
			document.body.setAttribute("data-theme",userTheme);
		}
		if(userProfile && userProfile.user && userProfile.user.organization){
			this.props.listOrganizationalUnitsForParent({"ParentId":userProfile.user.organization}, userProfile.user.accountVersion);
		}

		document.body.setAttribute("data-width",userNav?'wide':'narrow');
		document.addEventListener(
		  "SessionExpired",
		  (e) => {
				this.props.userTimeout(true);
		  },
		  false
		);
	}

	componentDidUpdate(prevProps) {
		const { userProfile, userTheme, userNav } = this.props;
		if(JSON.stringify(userProfile) !== JSON.stringify(prevProps.userProfile)){
			this.props.listOrganizationalUnitsForParent({"ParentId":userProfile.user.organization}, userProfile.user.accountVersion);
		}
		if(this.props.router.location.pathname === '/login'){
			document.body.setAttribute("data-theme",'light');
		}else if(userTheme !== prevProps.userTheme){
			document.body.setAttribute("data-theme",userTheme);
		}
		if(userNav !== prevProps.userNav){
			document.body.setAttribute("data-width",this.props.userNav?'wide':'narrow');
		}
  }


	render() {
		const { userProfile, userSessionTimeout } = this.props;
		var userRole = (userProfile.user) ? userProfile.user.role : null;
		var rootUrl = "/";

		const PrivateRoute = ({ component: Component, isAuthenticated, typeAccount, superadminOnly, ...rest}) => (
			<Route
				{...rest}
				render={props => ((isAuthenticated || userSessionTimeout) ?
					(userProfile.user.superadmin && superadminOnly) ? <Component {...props} /> :
					((typeAccount && (!typeAccount.includes(userRole)))) ? <Redirect to={rootUrl} /> : <Component {...props} />
					:
					(<Redirect to="/login" />)
				)}
			/>
		);

		var classMainContent =  'mainContent';
		return (
			<div className="App">
				{
					(this.props.router.location.pathname !== '/login') ?
					<div>
						 <TopNav SelectChannel={this.SelectChannel}/>
						 <SideNav/>
			 					<Drawer
									title="Session Expired"
			 		        placement={`right`}
			 		        closable={false}
			 		        open={userSessionTimeout}
			 		        key={`right`}
			 		      >
									<span>Your session has expired. Please log in again to continue.</span>
			 						<LoginCard userSessionTimeout={true}/>
			       		</Drawer>
					</div>
					:
					""
				}

				<Switch>
					<Route component={ScrollToTopPage} />
				</Switch>
				<div className={classMainContent} >
					<Switch>
						<Route path="/login" component={Login} />
						<PrivateRoute exact path="/" component={Organization} isAuthenticated={this.props.userAuthenticated } typeAccount={['admin']}/>
						<PrivateRoute exact path="/organization/new" component={OrganizationNew} isAuthenticated={this.props.userAuthenticated } typeAccount={['admin']} superadminOnly={true}/>
						<PrivateRoute exact path="/organization/:id?" component={OrganizationDetail} isAuthenticated={this.props.userAuthenticated } typeAccount={['admin']} superadminOnly={true}/>
						<PrivateRoute exact path="/client/new" component={ClientNew} isAuthenticated={this.props.userAuthenticated } typeAccount={['admin']}/>
						<PrivateRoute exact path="/client/:id?" component={Client} isAuthenticated={this.props.userAuthenticated } typeAccount={['admin']}/>
						<PrivateRoute exact path="/user" component={UserProfile} isAuthenticated={this.props.userAuthenticated } typeAccount={['admin']}/>
						<Route path="*" exact component={NotFound} />
					</Switch>
				</div>
			</div>
		);
	}
}


const mapStateToProps = state => ({
	router: state.router,
	userAuthenticated: state.user.userAuthenticated,
	userSessionTimeout: state.user.userSessionTimeout,
	userProfile: state.user.userProfile,
	userTheme: state.user.userTheme,
	userNav: state.user.userNav
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			userTimeout,
			listOrganizationalUnitsForParent
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
