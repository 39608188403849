import userApi from './api/userApi';

export const USER_LOGIN = 'USER/LOGIN';
export const USER_LOGOUT = 'USER/LOGOUT';
export const USER_UPDATE_ORGANIZATION = 'USER/UPDATE_ORGANIZATION';
export const USER_UPDATE_THEME = 'USER/UPDATE_THEME';
export const USER_UPDATE = 'USER/UPDATE';

export const USER_LIST = 'USER/LIST';
export const USER_SIDENAV = 'USER/SIDENAV';
export const USER_SESSIONTIMEOUT = 'USER/SESSIONTIMEOUT';

export const AUDIT_LIST = 'AUDIT/LIST';
export const APP_TITLE = 'APP/TITLE';


const currTimestamp = Math.round(new Date().getTime());

if (localStorage.u) {
	const parseStorage = JSON.parse(localStorage.u);
	if (parseStorage.token && parseStorage.token.expiresIn) {
		if (currTimestamp > new Date(parseStorage.token.expiresIn).getTime()) {
			localStorage.removeItem('u');
			window.parent.location = `${window.parent.location.origin}/login`;
		}
	}
}

let userProfile = (localStorage.u) ? JSON.parse(localStorage.u) : {};

let defaultDark = false;//window.matchMedia('(prefers-color-scheme: dark)').matches;

const initialState = {
	userAuthenticated: (localStorage.u) ? true : false,
	userProfile,
	userData: [],
	userNav: window.innerWidth <= 768,
	userTheme: (localStorage.theme)?(localStorage.theme):defaultDark?'dark':'light',
	appTitle: '',
	userSessionTimeout: false
}

export default (state = initialState, action) => {
	switch (action.type) {
		case USER_LOGIN:
			return {
				...state,
				userAuthenticated: true,
				userProfile: action.payload,
				userSessionTimeout: false
			};
		case USER_UPDATE:
			return {
				...state,
				userProfile: action.payload,
			};
		case USER_LOGOUT:
			return {
				...state,
				userAuthenticated: false
			};
		case USER_LIST:
			return {
				...state,
				userData: action.payload
			};
		case USER_SIDENAV:
			return {
				...state,
				userNav: !action.payload
			};
		case USER_SESSIONTIMEOUT:
			return {
				...state,
				userSessionTimeout: action.payload
			};
		case AUDIT_LIST:
			return {
				...state,
				auditList: action.payload
			};
		case APP_TITLE:
			return {
				...state,
				appTitle: action.payload
			};
		case USER_UPDATE_ORGANIZATION:
			return {
				...state,
				selectedOrganization: action.payload
			};
		case USER_UPDATE_THEME:
			return {
				...state,
				userTheme: action.payload
			};
		default:
			return state;
	}
};

export const userConfirmPassword = (password) => {
	return dispatch => {
		return userApi.UserConfirmPassword({email: JSON.parse(localStorage.u).user.email, password}).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const userLogin = (payload) => {
	return dispatch => {
		return userApi.UserLogin(payload).then(data => {
			if (data.user.role) {
				if(data.user.email === 'admin@onwardsmg.com'){
					data.user.superadmin = true;
				}
				if(data.user.email === 'admin@onwards.pro'){
					data.user.superadmin = true;
				}
				localStorage.setItem('u', JSON.stringify(data));
				dispatch({
					type: USER_LOGIN,
					payload: data
				});
			}
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const userLogout = () => {
	return dispatch => {
		dispatch({
			type: USER_LOGOUT
		});
		localStorage.removeItem('u');
	};
};

export const userInsert = (payload) => {
	return dispatch => {
		return userApi.UserInsert(payload).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const userUpdate = (payload, id) => {
	return dispatch => {
		return userApi.UserUpdate(payload, id).then(data => {
			userProfile = (localStorage.u) ? JSON.parse(localStorage.u) : {};
			userProfile.user = data;
			localStorage.setItem('u', JSON.stringify(userProfile));
			dispatch({
				type: USER_UPDATE,
				payload: userProfile
			});

			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const userUpdateOrganization = (data) => {
	return dispatch => {
		dispatch({
			type: USER_UPDATE_ORGANIZATION,
			payload: data
		});
		localStorage.setItem('selectedOrganization', data);
	};
};

export const userUpdateTheme = (data) => {
	return dispatch => {
		dispatch({
			type: USER_UPDATE_THEME,
			payload: data
		});
		localStorage.setItem('theme', data);
	};
};

export const userList = () => {
	return dispatch => {
		return userApi.UserList().then(data => {
			dispatch({
				type: USER_LIST,
				payload: data
			});
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const userDelete = (email) => {
	return dispatch => {
		return userApi.UserDelete({"email":email}).then(data => {
			return data;
		}).catch(error => {
			throw(error);
		});
	};
};

export const userSideNav = (data) => {
	return dispatch => {
		dispatch({
			type: USER_SIDENAV,
			payload: data
		});
	};
};


export const userTimeout = (data) => {
	return dispatch => {
		dispatch({
			type: USER_SESSIONTIMEOUT,
			payload: data
		});
	};
};

export const appTitleUpdate = (data) => {
	return dispatch => {
		dispatch({
			type: APP_TITLE,
			payload: data
		});
	};
};
