import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Button, message } from 'antd';
import { Animated } from "react-animated-css";

import './Organization.css';


import {
	userInsert
} from '../../modules/user';


const FormItem = Form.Item;

class OrganizationNew extends Component {
	formRef = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			loading:true
		}
	}

	async componentDidMount() {
		this.setState({loading:true});
		if (this.formRef.current) {
			const initialValues = {
				name: "",
				description: "",
			};
			this.formRef.current.setFieldsValue(initialValues);
		}
		this.setState({loading:false});
	}

	async componentDidUpdate(prevProps) {


	}

	handleSubmit = async (values) => {
		this.setState({ loading: true });
		let result = await this.props.userInsert(values);
		if(result){
			message.success(`Create Reseller (${values.accountPrefix}) Success`);
			const initialValues = {
				email: "",
				password: "",
				name: "",
				accountPrefix: "",
			};
			this.formRef.current.setFieldsValue(initialValues);
		}else{
			this.setState({ loading: false });
		}
	}




	vaildateFormUrl = (rule, value, callback) => {
		callback();
	}

	render() {
		// const { organizationalUnits, userProfile } = this.props;

		const { loading } = this.state;
		// const data = organizationalUnits.find(o=>o.ParentID === userProfile.user.organization && o.ID === id);

		const initialValues = {
			name: "",
			description: "",
		};

		const formItemLayout = {
			labelCol: { span: 4 },
			wrapperCol: { span: 20 },
		};


		return (
			<Animated animationIn="fadeIn">
				<div className="mainClientContentWrapper" style={{ paddingBottom: (this.props.play) ? '100px' : '20px' }}>
					<Row>
						<Col md={24} lg={24} xl={24} xxl={24}>
								<Row>
									 <Col span={24}>
										<Form ref={this.formRef} name="clientForm" onFinish={this.handleSubmit} initialValues={initialValues} >
											<FormItem {...formItemLayout} label="Name" name="name" rules={[{ required: true, message: 'Please input reseller name' }]}>
												<Input disabled={false} placeholder="Administrator" />
											</FormItem>
											<FormItem {...formItemLayout} label="Email" name="email" rules={[{ required: true, message: 'Please input reseller email' }]}>
												<Input disabled={false} placeholder="Reseller Email" />
											</FormItem>
											<FormItem {...formItemLayout} label="Password" name="password" rules={[{ required: true, message: 'Please input reseller password' }]}>
												<Input disabled={false} type="password" placeholder="Reseller Password" />
											</FormItem>
											<FormItem {...formItemLayout} label="accountPrefix" name="accountPrefix" rules={[{ required: true, message: 'Please input reseller accountPrefix' }]}>
												<Input disabled={false} placeholder="Reseller accountPrefix i.e. domain name" />
											</FormItem>
										</Form>
									</Col>
									</Row>
									<Row>
									<Col span={12}>
									</Col>
									<Col span={12}>
										<Button key="submit" form="clientForm" type="primary" htmlType="submit" disabled={loading} >Create Reseller Organization</Button>
									</Col>
								</Row>
						</Col>
					</Row>
				</div>
			</Animated>
		);
	}


}

const mapStateToProps = state => ({
	router: state.router,
	userProfile: state.user.userProfile,
	organizationalUnits: state.byteplus.organizationalUnits
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			userInsert
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationNew);
