import Service from '../utils/service';

export const ListOrganizationalUnitsForParent = 'ORG/ListOrganizationalUnitsForParent';
export const ListOrganizationalUnitsForOtherParent = 'ORG/ListOrganizationalUnitsForOtherParent';
export const UpdateOrganizationalUnit = 'ORG/UpdateOrganizationalUnit';
export const CreateOrganizationalUnit = 'ORG/CreateOrganizationalUnit';

class OrgService extends Service {
    constructor(options) {
        super(Object.assign(Object.assign({}, options), { host:"open.byteplusapi.com", region:"ap-singapore-1", defaultVersion: "2022-01-01", serviceName: "organization" }));
        this.DescribeOrganization = this.createAPI("DescribeOrganization");
        this.CreateOrganization = this.createAPI("CreateOrganization");
        this.DeleteOrganization = this.createAPI("DeleteOrganization");
        this.ListAccounts = this.createAPI("ListAccounts");
        this.CreateAccount = this.createAPI("CreateAccount");
        this.UpdateAccount = this.createAPI("UpdateAccount");
        this.EnableConsoleLogin = this.createAPI("EnableConsoleLogin");
        this.DisableConsoleLogin = this.createAPI("DisableConsoleLogin");
        this.ChangeAccountSecureContactInfo = this.createAPI("ChangeAccountSecureContactInfo");
        this.CancelChangeAccountSecureContactInfo = this.createAPI("CancelChangeAccountSecureContactInfo");
        this.RetryChangeAccountSecureContactInfo = this.createAPI("RetryChangeAccountSecureContactInfo");
        this.GetAccountSecureContactInfo = this.createAPI("GetAccountSecureContactInfo");
        this.InviteAccount = this.createAPI("InviteAccount");
        this.RejectQuitApplication = this.createAPI("RejectQuitApplication");
        this.DescribeQuitApplication = this.createAPI("DescribeQuitApplication");
        this.ReInviteAccount = this.createAPI("ReInviteAccount");
        this.ListInvitations = this.createAPI("ListInvitations");
        this.QuitOrganization = this.createAPI("QuitOrganization");
        this.RemoveAccount = this.createAPI("RemoveAccount");
        this.CancelInvitation = this.createAPI("CancelInvitation");
        this.DescribeAccountInvitation = this.createAPI("DescribeAccountInvitation");
        this.DescribeAccount = this.createAPI("DescribeAccount");
        this.AcceptInvitation = this.createAPI("AcceptInvitation");
        this.AcceptQuitApplication = this.createAPI("AcceptQuitApplication");
        this.MoveAccount = this.createAPI("MoveAccount");
        this.RejectInvitation = this.createAPI("RejectInvitation");

        this.UpdateServiceControlPolicy = this.createAPI("UpdateServiceControlPolicy");
        this.ListTargetAttachmentsForServiceControlPolicy = this.createAPI("ListTargetAttachmentsForServiceControlPolicy");
        this.DeleteServiceControlPolicy = this.createAPI("DeleteServiceControlPolicy");
        this.GetServiceControlPolicy = this.createAPI("GetServiceControlPolicy");
        this.DetachServiceControlPolicy = this.createAPI("DetachServiceControlPolicy");
        this.EnableServiceControlPolicy = this.createAPI("EnableServiceControlPolicy");
        this.GetServiceControlPolicyEnablement = this.createAPI("GetServiceControlPolicyEnablement");
        this.CreateServiceControlPolicy = this.createAPI("CreateServiceControlPolicy");
        this.ListServiceControlPolicies = this.createAPI("ListServiceControlPolicies");
        this.ListServiceControlPolicyAttachmentsForTarget = this.createAPI("ListServiceControlPolicyAttachmentsForTarget");
        this.DisableServiceControlPolicy = this.createAPI("DisableServiceControlPolicy");
        this.AttachServiceControlPolicy = this.createAPI("AttachServiceControlPolicy");
        this.DescribeOrganizationalUnit = this.createAPI("DescribeOrganizationalUnit");
        this.UpdateOrganizationalUnit = this.createAPI("UpdateOrganizationalUnit");
        this.ListOrganizationalUnitsForParent = this.createAPI("ListOrganizationalUnitsForParent");
        this.DeleteOrganizationalUnit = this.createAPI("DeleteOrganizationalUnit");
        this.ListOrganizationalUnits = this.createAPI("ListOrganizationalUnits");
        this.CreateOrganizationalUnit = this.createAPI("CreateOrganizationalUnit");

    }
}
class UserService extends Service {
    constructor(options) {
        super(Object.assign(Object.assign({}, options), { host:"open.byteplusapi.com", region:"ap-singapore-1", defaultVersion: "2018-01-01", serviceName: "iam" }));
        this.CreateUser = this.createAPI("CreateUser");
        this.ListUsers = this.createAPI("ListUsers");
        this.CreateLoginProfile = this.createAPI("CreateLoginProfile");
    }
}
class STSService extends Service {
    constructor(options) {
        super(Object.assign(Object.assign({}, options), { host:"open.byteplusapi.com", region:"ap-singapore-1", accessKeyId:"AKAPMzlmODA2Zjk3YzZiNDFkN2IyMmRmNjFlODc2NGJkM2M",secretKey:"WlRObVpEWTBOREU1WXpBM05HTTVNemsxT1dGak5HTTFNV1ZsTjJWa1pXRQ==", defaultVersion: "2018-01-01", serviceName: "sts" }));
        this.AssumeRole = this.createAPI("AssumeRole");
    }
}

const _orgService = new OrgService();
const KEYS = [{
    OrgAccessKeyId: "AKAPM2MwZjIxMzdjZWQ4NDBlMGIyZDI2M2ZmYTA5MTRkYjk",
    OrgSecretKey: "WlRRMk1ESmpNRGRoTXpjd05EWTFNbUV5Wm1JMFltSTFNRFZrTlRNd01XUQ==",
    StsAccessKeyId: "AKAPZGM3ZjlhYmZjMjJkNDgyMmE0YTYxMTY0NmYzOTIwYzA",
    StsSecretKey: "T1RJMVpqZzVNemN4TnpBME5EUTJNR0U0TmpCaE5qa3pZVGRqTTJFeVpEUQ==",
  },
  {
    OrgAccessKeyId: "AKAPMTJkYzY1Nzg0Y2I4NDI3N2I3ZDZlMWRkOWRlNGIzYmI",
    OrgSecretKey: "T1dFM09XSTNaR1k0WVdVNU5HVmxNamd6TjJRMllqVmtOamxtTVRRME5Uaw==",
    StsAccessKeyId: "AKAPZGM3ZjlhYmZjMjJkNDgyMmE0YTYxMTY0NmYzOTIwYzA",
    StsSecretKey: "WmpKbE4yUTRNMk15WlRrMU5HWXlNRGd5TlROaVl6Um1ZV00zWWpWa01XUQ==",
  }
];
export const orgService = _orgService;
export const iamService = new UserService();
export const _stsService = new STSService();

const initialState = {
  organizationalUnits: [],
  users: [],
}

export default (state = initialState, action) => {
	switch (action.type) {
		case ListOrganizationalUnitsForParent:
			return {
				...state,
				organizationalUnits: action.payload
			};
		case UpdateOrganizationalUnit:
			return {
				...state,
				organizationalUnit: action.payload
			};
    case CreateOrganizationalUnit:
      return {
        ...state,
        organizationalUnit: action.payload
      };
		default:
			return state;
	}
};

export const listOrganizationalUnitsForParent = (payload, version) => {
  _orgService.setAccessKeyId(KEYS[version].OrgAccessKeyId);
  _orgService.setSecretKey(KEYS[version].OrgSecretKey);
	return dispatch => {
    return _orgService.ListOrganizationalUnitsForParent(payload).then(data => {
      dispatch({
        type: ListOrganizationalUnitsForParent,
        payload: data.Result.SubUnitList
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};

export const listOrganizationalUnitsForOtherParent = (payload, version) => {
  _orgService.setAccessKeyId(KEYS[version].OrgAccessKeyId);
  _orgService.setSecretKey(KEYS[version].OrgSecretKey);
	return dispatch => {
    return _orgService.ListOrganizationalUnitsForParent(payload).then(data => {
      dispatch({
        type: ListOrganizationalUnitsForOtherParent,
        payload: data.Result.SubUnitList
      });
      return data.Result.SubUnitList;
    }).catch(error => {
      throw(error);
    });
	};
};

export const updateOrganizationalUnit = (payload, version) => {
  _orgService.setAccessKeyId(KEYS[version].OrgAccessKeyId);
  _orgService.setSecretKey(KEYS[version].OrgSecretKey);
	return dispatch => {
    return _orgService.UpdateOrganizationalUnit(payload).then(data => {
      dispatch({
        type: UpdateOrganizationalUnit,
        payload: data.ResponseMetadata
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};

export const createOrganizationalUnit = (payload, version) => {
  _orgService.setAccessKeyId(KEYS[version].OrgAccessKeyId);
  _orgService.setSecretKey(KEYS[version].OrgSecretKey);
	return dispatch => {
    return _orgService.CreateOrganizationalUnit(payload).then(data => {
      dispatch({
        type: CreateOrganizationalUnit,
        payload: data.Result
      });
      return data;
    }).catch(error => {
      throw(error);
    });
	};
};
