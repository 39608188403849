import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Card, Button } from 'antd';
import { Animated } from "react-animated-css";


import './Organization.css';

import {
	listOrganizationalUnitsForOtherParent
} from '../../modules/byteplus';


import {
	getAccountByOrgUnitId,
	updateAccountActive,
	enableConsoleLogin,
	createFinancialRelation
} from '../../modules/api';

class OrganizationDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading:true,
			organizationalUnits: []
		}
	}

	async componentDidMount() {
		const { userProfile } = this.props;
		const { id } = this.props.match.params;
		this.setState({loading:true});
		let organizationalUnits = await this.props.listOrganizationalUnitsForOtherParent({"ParentId":id}, userProfile.user.accountVersion);
		organizationalUnits = await Promise.all(organizationalUnits.map(async ou => {
			let info = await this.props.getAccountByOrgUnitId(ou.ID);
			return {...ou, info}
		}));
		this.setState({loading:false, organizationalUnits});
	}

	async componentDidUpdate(prevProps) {

	}

	async updateAccountActive(info) {
		const { userProfile } = this.props;
		const { id } = this.props.match.params;
		this.setState({loading:true});
		await this.props.enableConsoleLogin(info.AccountId);
		await this.props.createFinancialRelation({
		    "SubAccountID": Number(info.AccountId),
		    "Relation": 1,
		    "AccountAlias": info.ShowName
		});
		await this.props.updateAccountActive(info._id);
		let organizationalUnits = await this.props.listOrganizationalUnitsForOtherParent({"ParentId":id}, userProfile.user.accountVersion);
		organizationalUnits = await Promise.all(organizationalUnits.map(async ou => {
			let info = await this.props.getAccountByOrgUnitId(ou.ID);
			return {...ou, info}
		}));
		this.setState({loading:false, organizationalUnits});
	}


	render() {
		const { organizationalUnits } = this.state;
		const _ou = organizationalUnits;
		return (
			<Animated animationIn="fadeIn">
				<div className="mainContentWrapper" style={{ paddingBottom: (this.props.play) ? '100px' : '20px' }}>
					<Row>
						<Col md={24} lg={24} xl={24} xxl={24}>
								<Row>
									 {_ou && _ou.map((ou,i)=>
										 <Col key={i} xs={24} md={12} lg={6} xl={6} xxl={6}>
										 <Card
								       title={ou.Name}
								       extra={ou.info.AccountName}
								     >
											 {ou.info.status === "Active" ?
											 	<p>{ou.info.status}</p>:
											 	<p>
												<Button type="link" onClick={()=>{this.updateAccountActive(ou.info)}}>Activate Account</Button>
												</p>
										 		}
								     </Card>
										 </Col>
									 )
									 }

								</Row>
						</Col>
					</Row>
				</div>
			</Animated>
		);
	}
}

const mapStateToProps = state => ({
	router: state.router,
	userProfile: state.user.userProfile,
	organizationalUnits: state.byteplus.organizationalUnits

});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			listOrganizationalUnitsForOtherParent,
			getAccountByOrgUnitId,
			updateAccountActive,
			enableConsoleLogin,
			createFinancialRelation
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetail);
